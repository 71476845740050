<template>
	<nav class="nav text-body-3" :class="{ 'nav--collapsed': !isMenuVisible }">
		<router-link class="logo nav__logo" :class="{ 'logo--small': !isMenuVisible, 'nav__logo--open': !isMenuVisible }" to="/">
			Main Page
		</router-link>

		<router-link
			v-if="account.account_integration !== 'rtb' && isSender"
			v-tooltip.bottom="isMenuVisible ? '' : 'Send Message'"
			class="cta cta--plus cta--img nav__cta text-button"
			to="/pub/messages/new"
		>
			Send Message
		</router-link>

		<ul class="nav__list menu">
			<li>
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Statistics'"
					class="menu__item menu__item--statistics"
					:to="user.role_alias === 'pub-lite' ? '/pub/statistics/messages' : '/pub/statistics/revenue'"
					@click="toggleMenuItem"
				>
					Statistics
				</router-link>
			</li>
			<li v-if="account.account_integration !== 'rtb'">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Sites'"
					class="menu__item menu__item--sites"
					to="/pub/sites"
					@click="toggleMenuItem"
				>
					Sites
				</router-link>
			</li>
			<li v-if="account.account_integration !== 'rtb' && isSender">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Messages'"
					class="menu__item menu__item--messages"
					to="/pub/messages"
					@click="toggleMenuItem"
				>
					Messages
				</router-link>
			</li>
			<li v-if="user.role_alias !== 'pub-lite'" class="navigation__item">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Earnings'"
					class="menu__item menu__item--earnings"
					to="/pub/earnings"
					@click="toggleMenuItem"
				>
					Earnings
				</router-link>
			</li>
			<li v-if="user.role_alias !== 'pub-lite'" class="navigation__item">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Payouts'"
					class="menu__item menu__item--finance"
					:class="{ 'router-link-active': $route.path.includes('/pub/payouts') }"
					to="/pub/payouts"
					@click="toggleMenuItem"
				>
					Payouts
				</router-link>
			</li>

			<template v-if="isShowApiDocs && account.is_api_enabled && user.role_alias !== 'pub-lite'">
				<li class="navigation__item">
					<router-link
						v-tooltip.bottom="isMenuVisible ? '' : 'API'"
						class="menu__item menu__item--executor"
						to="/docs"
						@click="toggleMenuItem"
					>
						API
					</router-link>
				</li>
			</template>

			<li v-if="user.role_alias !== 'pub-lite'">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Referrals'"
					to="/referrals"
					class="menu__item menu__item--referrals"
					@click="toggleMenuItem"
				>
					Referrals
				</router-link>
			</li>

			<li>
				<a
					v-tooltip.bottom="isMenuVisible ? '' : 'Help Center'"
					href="https://help.rollerads.com/en/collections/6677642-for-publishers"
					target="_blank"
					class="menu__item menu__item--help-center menu__item--without-focus"
					@click="toggleMenuItem"
				>
					Help Center
				</a>
			</li>
		</ul>

		<manager-badge
			v-if="manager.id && manager.contacts?.length"
			:manager-id="manager.id"
			:manager-name="manager.name"
			:contacts="manager.contacts"
			account-type="pub"
		/>
	</nav>
</template>

<script>
	import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
	import EventBus from '@/services/local/EventBusService';
	import User from '@/services/local/UserService';
	import { ManagerBadge } from '@/components/ManagerBadge';
	import useMenuStore from '@/composables/useMenuStore';

	export default defineComponent({
		name: 'LeftMenu',

		components: {
			ManagerBadge,
		},
		setup() {
			const account = ref(User.currentUser.account || {});
			const user = ref(User.currentUser.user || {});
			const isMenuVisible = ref(false);
			const { setMenu } = useMenuStore();

			const changePubMenu = () => {
				account.value = User.currentUser.account || {};
			};
			const menuToggled = (val) => {
				isMenuVisible.value = isMenuVisible.value !== val;
				setMenu(isMenuVisible.value);
			};

			onMounted(() => {
				User.on('change', changePubMenu);
				EventBus.on('menuToggled', menuToggled);
			});
			onUnmounted(() => {
				User.off('change', changePubMenu);
				EventBus.off('menuToggled', menuToggled);
			});

			return {
				account,
				user,
				isMenuVisible,
			};
		},

		data() {
			return {
				isSender: User.isFeatureEnabled('sender') || false,
				allowBeta: User.isFeatureEnabled('beta'),
				isShowApiDocs: User.isFeatureEnabled('api_docs'),

				manager: User.currentUser.manager_info || {},
			};
		},

		mounted() {
			localStorage.isMenuVisible === 'false' ? (this.isMenuVisible = false) : (this.isMenuVisible = true);
			this.setMenuStore(this.isMenuVisible);
		},

		methods: {
			toggleMenuItem() {
				if (window.innerWidth >= 1024) {
					return;
				} else if (window.innerWidth >= 768) {
					this.isMenuVisible = false;
					localStorage.isMenuVisible = false;
				} else {
					this.isMenuVisible = true;
					localStorage.isMenuVisible = true;
				}

				this.setMenuStore(this.isMenuVisible);
			},

			setMenuStore(value) {
				const { setMenu } = useMenuStore();
				setMenu(value);
			},
		},
	});
</script>
